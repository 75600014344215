import { Application } from "@hotwired/stimulus"
const Stimulus = Application.start()

import JobOptimizerResultController from "./job-optimizer-result-controller"
import CollapseCheckController from "./collapse_check_controller"
import BmsWidgetFormController from "./bms-widget-form-controller"
import OpenModalController from "./open-modal-controller"
import BootstrapModalController from "./bootstrap_modal_controller"
import PasswordMaskToggleController from "./password_mask_toggle_controller"
import IframeResizerController from "./iframe-resizer-controller"
import CopyButtonController from "./copy-button-controller"
import TranslateController from "./translate_controller"
import AlertCloseController from "./alert_close_controller"
import HighchartsController from "./highcharts_controller"
import TagCloudController from "./tagcloud_controller"
import PasswordMaskController from "./password-mask-controller"
import CurrencyMaskController from './currency-mask-controller'
import SelectAllController from './select-all-controller'

import ReadMore from 'stimulus-read-more'
import { Autocomplete } from 'stimulus-autocomplete'

import TabsController from './common/tabs-controller'
import Reveal from './common/reveal-controller'

import ChoicesController from './lib/choices-controller'
import TinymceController from './lib/tinymce-controller'

import CrmPricingController from './registration/crm-pricing-controller'
import WalkInController from './registration/walk-in-controller'
import WalkInJobController from './registration/walk-in-job-controller'
import SalaryIntervalController from './registration/salary-interval-controller'
import PostcodeAutocompleteController from './registration/postcode-autocomplete-controller'

import TurnController from 'onboarding/controllers/turn_controller'
import InputVueController from 'onboarding/controllers/input_vue_controller'
import VColorPickerController from 'onboarding/controllers/v_color_picker_controller'
import VDescriptionController from 'onboarding/controllers/v_description_controller'
import DirectUploadController from 'onboarding/controllers/direct_upload_controller'
import VCompetencesController from 'onboarding/controllers/v_competences_controller'
import VSectorsController from 'onboarding/controllers/v_sectors_controller'
import UserAddressesController from 'onboarding/controllers/user_addresses_controller'

Stimulus.register("job-optimizer-result", JobOptimizerResultController)
Stimulus.register("collapse-check", CollapseCheckController)
Stimulus.register("bms-widget-form", BmsWidgetFormController)
Stimulus.register("open-modal", OpenModalController)
Stimulus.register("password-mask-toggle", PasswordMaskToggleController)
Stimulus.register("iframe-resizer", IframeResizerController)
Stimulus.register("tabs", TabsController)
Stimulus.register("copy-button", CopyButtonController)
Stimulus.register("translate", TranslateController)
Stimulus.register("alert-close", AlertCloseController)
Stimulus.register("highcharts", HighchartsController)
Stimulus.register("tagcloud", TagCloudController)
Stimulus.register("password-mask", PasswordMaskController)
Stimulus.register('currency-mask', CurrencyMaskController)

Stimulus.register('read-more', ReadMore)
Stimulus.register('autocomplete', Autocomplete)

Stimulus.register('reveal', Reveal)

Stimulus.register('tinymce', TinymceController)
Stimulus.register('choices', ChoicesController)

Stimulus.register('crm-pricing', CrmPricingController)
Stimulus.register('walk-in', WalkInController)
Stimulus.register('walk-in-job', WalkInJobController)
Stimulus.register('salary-interval', SalaryIntervalController)
Stimulus.register('postcode-autocomplete', PostcodeAutocompleteController)

Stimulus.register('turn', TurnController)
Stimulus.register('input-vue', InputVueController)
Stimulus.register('v-color-picker', VColorPickerController)
Stimulus.register('v-description', VDescriptionController)
Stimulus.register('v-competences', VCompetencesController)
Stimulus.register('v-sectors', VSectorsController)
Stimulus.register('direct-upload', DirectUploadController)
Stimulus.register('bootstrap-modal', BootstrapModalController)
Stimulus.register('user-addresses', UserAddressesController)
Stimulus.register('select-all', SelectAllController)
