import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  selectAll(e: PointerEvent) {
    e.preventDefault()
    this.element.querySelectorAll<HTMLInputElement>("input[type=checkbox]").forEach((checkbox) => {
      checkbox.checked = true
    })
  }
  unselectAll(e: PointerEvent) {
    e.preventDefault()
    this.element.querySelectorAll<HTMLInputElement>("input[type=checkbox]").forEach((checkbox) => {
      checkbox.checked = false
    })
  }
}

